import './wdyr';
import {getConfigData} from 'configCustom';
import {saveDataToLS} from 'utilsOld/configJSONUtils';
import config from 'devextreme/core/config';
import './shims';

import './index.scss';

config({licenseKey: process.env.REACT_APP_DX_LICENCE});

(async () => {
  const [userConfig] = await Promise.all([getConfigData()]);

  if (window) {
    // @ts-ignore
    window.D5AutoTest = {};
  }

  // @ts-ignore
  saveDataToLS(userConfig); //set default themes to local storage app_settings

  try {
    const {default: initApplication} = await import(/* webpackChunkName: "initApplication" */ './initApplication');
    initApplication(userConfig);
    const element = document.getElementById('global-spiner-wrapper');
    element?.remove();
  } catch (error) {
    /**
     * Обробка помилки завантаження чанку.
     * Цей блок коду перевіряє, чи помилка пов'язана із завантаженням чанку,
     * та якщо так, перезавантажує сторінку.  Це необхідно для коректного завантаження
     * застосунку у випадках, коли деякі файли не були завантажені через помилки мережі,
     * або інші проблеми.  Регулярний вираз перевіряє наявність ключових слів
     * "loading", "chunk", "failed" та хоча б однієї цифри в повідомленні про помилку,
     * що дозволяє ідентифікувати помилки, пов'язані саме із завантаженням чанків.
     */
    const regex = /^(?=.*loading)(?=.*chunk)(?=.*failed)(?=.*\d+).*$/i;
    if (error instanceof Error && regex.test(error.message)) {
      window.location.reload();
    }
  }
})();
export {mergeDeep} from './utilsOld/immutable-utils';
