import {produce} from 'immer';
import mergeWith from 'lodash/mergeWith';
import {isArray} from '../services/SecondaryMethods/typeUtils';

export function updateIn<T extends Record<string, any>, V>(
  obj: T,
  path: Array<keyof T>,
  valueModifier: (value: V) => V
) {
  return produce(obj, draft => {
    path.reduce((acc, pathItem, index) => {
      // @ts-ignore
      if (index === path.length - 1) return (acc[pathItem] = valueModifier(acc[pathItem]));

      // @ts-ignore
      if (!(pathItem in acc)) acc[pathItem] = {};

      // @ts-ignore
      return acc[pathItem];
    }, draft);
  });
}

export function setIn<T extends Record<string, any>, V>(obj: T, path: Array<keyof T>, value: V) {
  return updateIn(obj, path, () => value);
}
export const mergeDeep = <T>(state: T, newState: T) => {
  return produce(state, draft =>
    mergeWith(draft, newState, (objValue, srcValue) => {
      if (isArray(objValue)) {
        const uniq = new Set(objValue);
        srcValue.forEach((item: any) => {
          uniq.add(item);
        });

        return [...uniq];
      }
    })
  );
};
