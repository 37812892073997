const types: {[key: string]: string} = {
  '[object Array]': 'array',
  '[object Date]': 'date',
  '[object Object]': 'object',
  '[object String]': 'string',
  '[object Null]': 'null'
};

const type = function (object: Record<string, any>): string {
  const typeOfObject = Object.prototype.toString.call(object);

  return typeof object === 'object' ? types[typeOfObject] || 'object' : typeof object;
};

const isBoolean = function (object: any): boolean {
  return typeof object === 'boolean';
};

const isDate = function (object: any): boolean {
  return type(object) === 'date';
};

const isUndefined = (val: any) => val === undefined;

/**
 * Returns true if the passed value isn't null or undefined.
 * @param value
 */
const isDefined = function (value: any): boolean {
  return value !== null && !isUndefined(value);
};

const isFunction = function (object: any): boolean {
  return typeof object === 'function';
};

const isString = function (object: any): boolean {
  return typeof object === 'string';
};

const isNumeric = function (object: any): boolean {
  return (typeof object === 'number' && isFinite(object)) || !isNaN(object - parseFloat(object));
};

const isObject = function (value: any): boolean {
  return type(value) === 'object' && !isNull(value) && !isArray(value) && !isDate(value);
};

const isObjectOrNull = function (value: any): boolean {
  return (type(value) === 'object' || type(value) === 'null') && !isArray(value) && !isDate(value);
};

const isEmptyObject = function (object: Record<string, any>): boolean {
  return !Object.keys(object).length;
};

const isPromise = function (object: Record<string, any>): boolean {
  return object && isFunction(object.then);
};

const isNull = function (value: any): boolean {
  return type(value) === 'null';
};

const isArray = function (value: any): boolean {
  return type(value) === 'array';
};
const isArrayOfFiles = function (value: any): boolean {
  return isArray(value) && value.every((item: any) => item instanceof File);
};

const isArrayWithEmptyValues = (value: any) => {
  if (isEmptyValue(value)) return true;

  if (isArray(value)) {
    return value.some((item: any) => !isDefined(item));
  }

  return false;
};

function isEmptyValue(value: any) {
  switch (true) {
    case !isDefined(value):
    case value === '':
    case isArray(value) && !value.length:
    case isObject(value) && isEmptyObject(value):
      return true;
  }

  return false;
}

export {
  isFunction,
  isArray,
  isString,
  isNumeric,
  isBoolean,
  isDate,
  isDefined,
  isPromise,
  isEmptyObject,
  type,
  isObject,
  isNull,
  isObjectOrNull,
  isEmptyValue,
  isUndefined,
  isArrayWithEmptyValues,
  isArrayOfFiles
};
